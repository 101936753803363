import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/internal/operators/map";

const dataUrlThanSo = "https://api.mentor24.vn/ThanSo";
const dataUrlContactInfor = "https://api.mentor24.vn/Contacts";
const dataUrlContents = "https://api.mentor24.vn/Contents";
const dataUrlCustomer = "https://api.mentor24.vn/customerInfo";
const dataUrlPrintContent = "https://api.mentor24.vn/printThanSo";

const token = "token:LDy3jn3Qslm149nLfKr2";
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: token,
  }),
};
@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(private http: HttpClient) {}

  public getDiscoverLifeInfor(data: {
    name: string;
    birthday: string;
  }): Observable<any> {
    return this.http
      .get<any>(
        `${dataUrlThanSo}?name=${data.name}&birthday=${data.birthday}`,
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public sendContactInfor(data: any): Observable<any> {
    return this.http
      .post<any>(`${dataUrlContactInfor}`, data, httpOptions)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public getPageContent(data: any): Observable<any> {
    return this.http
      .get<any>(`${dataUrlContents}?page_key=${data}`, httpOptions)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public getCustomerInfor(id: any): Observable<any> {
    return this.http
      .get<any>(`${dataUrlCustomer}?id=${id}`, httpOptions)
      .pipe(
        map((data) => {
          return data;
        })
      );
  } 

  public getPrintContent(id: any): Observable<any> {
    return this.http
      .get<any>(`${dataUrlPrintContent}?id=${id}`, httpOptions)
      .pipe(
        map((data) => {
          return data;
        })
      );
  } 
}
